import React from 'react'
import styled from 'styled-components'

const CenteringContainer = styled.div`
  width: 100%;
  /* height: 200px; */
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`

const LayersContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  pointer-events: none;
  /* background-color: orange; */
  height: ${ ({ expanded }) => expanded ? '100%' : '10%' };
  width: 100%;
  transition: height 0.3s ease-in-out;
`

export const IsomorphLayerContainer = ({ children, expanded, onMouseEnter, onMouseLeave, onTouchStart }) => {
  return <CenteringContainer onMouseEnter={
    event => {
      if (!('ontouchstart' in document.documentElement)) {
        onMouseEnter(event)
      }
    }
  } onMouseLeave={
    event => {
      if (!('ontouchstart' in document.documentElement)) {
        onMouseLeave(event)
      }
    }
  } onClick={onTouchStart}>
    <LayersContainer expanded={expanded}>
      {children}
    </LayersContainer>
  </CenteringContainer>
}
