import React from 'react'
import styled from 'styled-components'

const CenteringContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: ${ props => props.backgroundColor };
  align-items: center;
  z-index: 1;
  transition: opacity, transform, filter, 0.5s ease-in-out;
  /* opacity: ${ ({ visible }) => visible ? '1' : '0.0' }; */
  transform: ${ ({ visible }) => visible ? 'translateY(0%)' : 'translateY(100%)' };
  /* filter: ${ ({ visible }) => visible ? 'none' : 'blur(10px)' }; */
  pointer-events: ${ ({ visible }) => visible ? 'auto' : 'none' };

`

const CloseButton = styled.div`
  position: absolute;
  cursor: pointer;
  padding: 0;
  margin: 0;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 0.8;
  z-index: 2;
  transition: opacity 0.3s ease-in-out;
  &:hover {
    opacity: 1;
  }
  &:before, &:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: ${ props => props.contrastColor };
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`

const DataContainer = styled.div`
  display:flex;
  max-width: 800px;
  width: 100%;
  flex-direction: column;
  padding: 30px;
`

const Window = styled.div`
  position: relative;
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 900px;
  width: 100%;
  height: 100%;

  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }
`

const Image = styled.img`
  height: 350px;
  border-radius: 20px;
  padding: 0;
  margin: 0;
  @media only screen and (max-width: 800px) {
    width: 80%;
    height: auto;
  }
`

export const ExternalLinkButton = styled.a`
  color: ${ props => props.contrastColor };
  text-align: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  margin-right: 10px;
  bottom: 40px;
  user-select: none;
  background-color: transparent;
  font-weight: 700;
  border: 2px solid ${ props => props.contrastColor };
  opacity: 0.9;
  outline: none;
  cursor: pointer;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  font-family: 'Montserrat', sans-serif;
  font-size: 0.8rem;
  transform: scale(1.0);
  &:hover {
    transform: scale(1.05);
    opacity: 1;
  }
`

const Title = styled.h2`
  color: ${ props => props.contrastColor };
  font-family: 'Montserrat', sans-serif;
`

const Description = styled.p`
  color: ${ props => props.contrastColor };
  font-size: 0.9rem;
  font-family: 'Inconsolata', sans-serif;
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const FullPageProjectDetail = ({ visible, onClickClose, ...rest }) => {
  return <CenteringContainer {...rest} visible={visible}>
    <CloseButton {...rest} onClick={onClickClose}/>
    <Window>
      <DataContainer>
        <Title {...rest}>{rest.name}</Title>
        <Description {...rest}>
          {rest.description}
        </Description>
        <ButtonContainer>
          {rest.links.map(link => {
            return <ExternalLinkButton key={link.name} {...rest} href={link.href}>{link.name}</ExternalLinkButton>
          })}
        </ButtonContainer>

      </DataContainer>
      <Image src={rest.imageURL}/>
    </Window>
  </CenteringContainer>
}
