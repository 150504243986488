import React from 'react'
import { IsomorphLayerContainer } from './IsomorphLayerContainer'
import { Isomorph } from './Isomorph'
import styled from 'styled-components'

const Crystal = styled(Isomorph)`
  background-color: ${ props => props.color };
  mix-blend-mode: screen;
  border-radius: ${ props => props.selected ? '20px' : '0px' };
  transition: all 0.3s ease-in-out;
  box-shadow: ${ props => props.color } 0 0 ${ props => props.selected ? '30px' : '0px' } 0px;
  transform: ${ props => props.selected
    ? 'rotate3d(1, 0, 0, 45deg) rotate3d(0, 0, 1, ' + props.endRotation + ') scale(0.8)'
    : 'rotate3d(1, 0, 0, 45deg) rotate3d(0, 0, 1, ' + props.startRotation + ') scale(0.8)'
} ;
`

const SmallCrystal = styled(Crystal)`
  transform: rotate3d(1, 0, 0, 45deg) rotate3d(0, 0, 1, 135deg) scale(0.5);
`

const Black = styled(Isomorph)`
  background-color: black;
  border-radius: 20px;
  box-shadow: black 0 0 30px 0px;
`

export const PrismIsomorph = () => {
  const [selected, setSelected] = React.useState(false)

  return <IsomorphLayerContainer
    expanded={selected}
    onMouseEnter={() => setSelected(true)}
    onMouseLeave={() => setSelected(false)}
    onTouchStart={() => setSelected(prev => !prev)}
  >

    <SmallCrystal color='#FF0000' style={{ bottom: '0%' }} selected={selected} />
    <SmallCrystal color='#00FF00' style={{ bottom: '5%' }} selected={selected} />
    <SmallCrystal color='#0000FF' style={{ bottom: '10%' }} selected={selected} />
    {/* <Black style={{ bottom: '25%' }} /> */}
    <Crystal color='#ff42a9' startRotation='45deg' endRotation='135deg' style={{ bottom: '20%' }} selected={selected}/>
    <Crystal color='#ffb86c' startRotation='-45deg' endRotation='-135deg' style={{ bottom: '30%' }} selected={selected}/>
    <Crystal color='#52f17a' startRotation='45deg' endRotation='135deg' style={{ bottom: '45%' }} selected={selected}/>
    <Crystal color='#69e9f1' startRotation='-45deg' endRotation='-135deg' style={{ bottom: '70%' }} selected={selected}/>

  </IsomorphLayerContainer>
}
