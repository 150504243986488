import React from 'react'
import { IsomorphLayerContainer } from './IsomorphLayerContainer'
import { Isomorph } from './Isomorph'
import styled from 'styled-components'
import logo from '../../images/youles_logo.png'

const Background = styled(Isomorph)`
  background-color: #f33c7b;
  border-radius: 30px;
`

const ChatContainer = styled(Isomorph)`
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
`

const ChatBubble1 = styled.div`
  display: inline;
  background-color: #6632ff;
  color: white;
  padding: 4px 14px;
  margin: 0 10px;
  font-size: 8px;
  border-radius: 10px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
`

const ChatBubble2 = styled(ChatBubble1)`
  background-color: #f33c7b;
`

const ChatBubble3 = styled(ChatBubble1)`
  background-color: #00cbcb;
  color:#4407a4;
  align-self: flex-end;
`

const Card1 = styled(Isomorph)`
  background-color: #4B26DC;
  border-radius: 30px;
`

const Card2 = styled(Isomorph)`
  background-color: #3A1B9E;
  border-radius: 30px;
`

const Card3 = styled(Isomorph)`
  background-color: white;
  border-radius: 30px;
`

const EmojiContainer = styled(Isomorph)`
  border-radius: 10px;
  justify-items: center;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  display: grid;
  grid-gap: 15px;
  & div {
    opacity: 1.0;
    color: white;
    text-align: center;
    font-size: 50px;
  }
`

const Logo = styled(Isomorph)`
  background-image: url(${ logo });
  background-position: center center;
  background-size: 80%;
  background-repeat: no-repeat;
  border-radius: 80px;
  background-color: #ff1651;
  transform: rotate3d(1, 0, 0, 45deg) rotate3d(0, 0, 1, 135deg) scale(0.8);
`

export const YoulesIsomorph = () => {
  const [selected, setSelected] = React.useState(false)

  return <IsomorphLayerContainer
    expanded={selected}
    onMouseEnter={() => setSelected(true)}
    onMouseLeave={() => setSelected(false)}
    onTouchStart={() => setSelected(prev => !prev)}
  >
    <Background style={{ bottom: '5%' }} />
    <Card2 style={{ bottom: '8%' }}></Card2>
    <Card1 style={{ bottom: '14%' }}></Card1>
    <EmojiContainer style={{ bottom: '20%' }}>
      <div>😎</div>
      <div>😘</div>
      <div>🐶</div>
      <div>🏖</div>
    </EmojiContainer>
    <Background style={{ bottom: '36%' }} />
    <Card1 style={{ bottom: '38%' }} />
    <Card2 style={{ bottom: '41%' }}></Card2>
    <Card3 style={{ bottom: '46%' }}></Card3>

    <Card1 style={{ bottom: '55%' }} />

    <ChatContainer style={{ bottom: '70%' }} >
      <ChatBubble1>Sure! I can help.</ChatBubble1>
      <ChatBubble2>Done ✅</ChatBubble2>
      <ChatBubble3>Thanks Youles!</ChatBubble3>
    </ChatContainer>

    <Logo style={{ bottom: '85%' }} />

  </IsomorphLayerContainer>
}
