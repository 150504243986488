import React from 'react'
import { IsomorphLayerContainer } from './IsomorphLayerContainer'
import { Isomorph } from './Isomorph'
import styled from 'styled-components'
import logo from '../../images/qs_logo.png'
import pattern from '../../images/pattern.png'

const Container = styled(Isomorph)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Logo = styled(Isomorph)`
  background-image: url(${ logo });
  background-position: center center;
  background-size: 80%;
  background-repeat: no-repeat;
  background-color: rgba(255,255,255,0.8);
  transform: initial;
  border-radius: 80px;
  transform: rotate3d(1, 0, 0, 45deg) rotate3d(0, 0, 1, -45deg) scale(0.8);
  box-shadow: rgba(0,0,0,.15) 0 0 10px 0px;
`

const WindowLayer = styled(Isomorph)`
  border-radius: 10px;
  background-color: #F5F7FA;
  transition: all 0.3s ease-in-out;
  box-shadow: ${ props => props.selected ? 'rgba(0,0,0,.15)' : 'rgba(0,0,0,0.01)' } 0 0 10px 0px;
`

const DotsLayer = styled(Isomorph)`
  border-radius: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  display: grid;
  grid-gap: 15px;
  transform: scale(0.8) rotate3d(1, 0, 0, 45deg) rotate3d(0, 0, 1, 135deg);
`

const Dot = styled.div`
  border-radius: 100px;
  background-color: ${ props => props.color };
  box-shadow: ${ props => props.color } 0 0 10px 0px;
`

const ButtonsLayer = styled(Isomorph)`
  border-radius: 10px;
  grid-template-columns: 1fr 1fr;
  display: grid;
  grid-gap: 10px;
  padding: 5px;
`

const TexturesLayer = styled(Isomorph)`
  border-radius: 10px;
  grid-template-columns: 1fr 1fr;
  display: grid;
  grid-gap: 10px;
  background-image: url(${ pattern });
  background-size: contain;
`

const Button = styled.div`
  border-radius: 10px;
  background-color: #444444;
  box-shadow: rgba(0,0,0,.15) 0 0 10px 0px;
`

const SecondaryButton = styled(Button)`
  border-radius: 10px;
  background-color: white;
  box-shadow: rgba(0,0,0,.15) 0 0 10px 0px;
`

const DropperLayer = styled(Isomorph)`
  border-radius: 20px;
  background-color: transparent;
  border: 4px dashed rgba(0,0,0,.06);
  &:after {
    position: absolute;
    content: "";
    display: block;
    background-color: #E9EDF4;
    width: 4px;
    height: 30px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
   &:before {
    position: absolute;
    content: "";
    display: block;
    background-color: #E9EDF4;
    width: 30px;
    height: 4px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
`

const TextDescriptionLayer = styled(Isomorph)`
  border-radius: 10px;
  background-color: #E9EDF4;
  transform: scale(0.9) rotate3d(1, 0, 0, 45deg) rotate3d(0, 0, 1, -45deg);
  border: 1px solid rgba(0,0,0,.03);
`

const Shadow = styled.div`
  background-color: rgba(0,0,0,.3);
  transition: all 0.3s ease-in-out;
  border-radius: 10px;
  filter: ${ ({ selected }) => selected ? 'blur(15px)' : 'blur(2px)' };
  height: ${ ({ selected }) => selected ? '70%' : '100%' };
  width: ${ ({ selected }) => selected ? '70%' : '100%' };
`

export const QswimwearIsomorph = () => {
  const [selected, setSelected] = React.useState(false)

  return <IsomorphLayerContainer
    expanded={selected}
    onMouseEnter={() => setSelected(true)}
    onMouseLeave={() => setSelected(false)}
    onTouchStart={() => setSelected(prev => !prev)}
  >
    <Container style={{ bottom: '0%' }}>
      <Shadow selected={selected}/>
    </Container>
    <WindowLayer style={{ bottom: '10%' }} selected={selected}/>
    <DotsLayer style={{ bottom: '12%' }}>
      <Dot color='#9FD48A' />
      <Dot color='#D0657F' />
      <Dot color='#DEDE8C' />
      <Dot color='#DFBA62' />
      <Dot color='#B97FCC' />
      <Dot color='#9FD48A' />
      <Dot color='#9FD48A' />
    </DotsLayer>
    <WindowLayer style={{ bottom: '22%' }} selected={selected}/>
    <TexturesLayer style={{ bottom: '28%' }} />
    <WindowLayer style={{ bottom: '35%' }} selected={selected}/>
    <TextDescriptionLayer style={{ bottom: '40%' }} selected={selected}/>
    <ButtonsLayer style={{ bottom: '48%' }}>
      <SecondaryButton></SecondaryButton>
      <SecondaryButton></SecondaryButton>
      <Button></Button>
      <Button></Button>
    </ButtonsLayer>
    <WindowLayer style={{ bottom: '60%' }} selected={selected}/>
    <DropperLayer style={{ bottom: '70%' }} />
    <Logo style={{ bottom: '85%' }}/>
  </IsomorphLayerContainer>
}
