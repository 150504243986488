import React from 'react'
import styled, { css } from 'styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { QswimwearIsomorph } from '../components/isomorph/QswimwearIsomorph'
import { YoulesIsomorph } from '../components/isomorph/YoulesIsomorph'
import { PrismIsomorph } from '../components/isomorph/PrismIsomorph'
import { FullPageProjectDetail } from '../components/FullPageProjectDetail'
import { Swipeable } from 'react-swipeable'
import QswimwearImage from '../images/qs_screenshot.png'
import YoulesImage from '../images/youles_screenshot.png'
import PrismImage from '../images/prism_screenshot.gif'

const MeContaier = styled.header`
  position: fixed;
  display: flex;
  color: ${ props => props.contrastColor };
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease-in-out;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  padding: 30px 40px;
  z-index: 1;
  width: 100%;
`

const Name = styled.span`
  transition: color 0.3s ease-in-out;
  & span {
    font-weight: 400;
  }
`

const PageContainer = styled.main`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  transition: background-color 0.3s ease-in-out;
  width: 100%;
  height: 100%;
`

const CurrentProjectContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 350px;
  width: 200px;
  @media only screen and (max-width: 800px) {
    transform: scale(0.9);
  }
`

const ProjectSlide = styled.div`
  /* background-color: green; */
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-in-out;
  ${ ({ slideState }) => {
    if (slideState === 0) {
      return css`
        transform: translateX(0%);
        `
    } else if (slideState === 1) {
      return css`
        opacity: 0.5;
        transform: translateX(100%) scale(0.8);
        `
    } else if (slideState === -1) {
      return css`
        opacity: 0.5;
        transform: translateX(-100%) scale(0.8);
        `
    } else if (slideState < -1) {
      return css`
        pointer-events: none;
        transform: translateX(-200%) scale(0.5);
        opacity: 0;
        `
    } else {
      return css`
        transform: translateX(200%) scale(0.5);
        pointer-events: none;
        opacity: 0;
        `
    }
  } }
`

const ProjectSlideTitle = styled.h2`
  color: ${ props => props.contrastColor };
  transition: color 0.3s ease-in-out;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  padding: 20px 0;
`

const AboutThisProjectButton = styled.button`
  position: absolute;
  color: ${ props => props.contrastColor };
  text-align: center;
  padding: 0.5rem 1rem;
  bottom: 40px;
  user-select: none;
  background-color: transparent;
  font-weight: 700;
  border: 2px solid ${ props => props.contrastColor };
  opacity: 0.7;
  outline: none;
  cursor: pointer;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  font-family: 'Montserrat', sans-serif;
  font-size: 0.8rem;
  &:hover {
    opacity: 1.0;
  }
  &:hover:after {
    top: -90%;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: -70%;
    left: 50%;
    transition: all 0.3s ease-in-out;
    transform: rotate(45deg) translateX(-50%);
    border-left: 2px solid ${ props => props.contrastColor };
    border-top: 2px solid ${ props => props.contrastColor };
    width: 20px;
    height: 20px;
  }
`

const PROJECT_DATA = [
  {
    name: 'Qswimwear',
    description: 'This project was created for a prestigious swimwear company. It helps users and internal employees with the creation and distribution of their own swimsuit designs. It came with a custom admin/CMS platform.',
    links: [
      { name: 'Website', href: 'https://designer.qswimwear.com' },
    ],
    imageURL: QswimwearImage,
    backgroundColor: 'white',
    contrastColor: '#42484F',
    isomorph: <QswimwearIsomorph />
  },
  {
    name: 'Youles',
    description: 'Youles is the first Dynamic Finance decision platform. Our technology offers a new way of understanding lifestyle, allowing young employees to choose how to enjoy their money and achieve their goals.',
    links: [
      { name: 'Website', href: 'https://youles.mx' },
      { name: 'iOS', href: 'https://apps.apple.com/mx/app/youles/id1488148178' },
      { name: 'Android', href: 'https://play.google.com/store/apps/details?id=mx.youles.app.android' }
    ],
    imageURL: YoulesImage,
    backgroundColor: 'rgb(68, 7, 164)',
    contrastColor: '#f33c7b',
    isomorph: <YoulesIsomorph />
  },
  {
    name: 'Prism',
    description: 'Open-Source Sketch plugin with 1000+ stars. It helps accelerate developer handoff by generating color palettes that can be directly exported to code formats like SCSS and UIColor.',
    links: [
      { name: 'Github', href: 'https://github.com/ment-mx/Prism' }
    ],
    imageURL: PrismImage,
    backgroundColor: 'black',
    contrastColor: '#d2d2d2',
    isomorph: <PrismIsomorph />
  },
]

const IndexPage = () => {
  const [currentIndex, setCurrentIndex] = React.useState(0)
  const [isShowingDetail, setIsShowingDetail] = React.useState(false)
  const currentProjectData = PROJECT_DATA[currentIndex]

  const slideClick = React.useCallback(slideState => {
    setCurrentIndex(prev => prev + slideState)
  }, [])

  return (
    <Layout>
      <SEO title='Adrxx' keywords={[`gatsby`, `application`, `react`]} />
      <MeContaier {...currentProjectData}>
        <Name >Adrián <span>Rubio</span></Name>
      </MeContaier>
      <FullPageProjectDetail {...currentProjectData} visible={isShowingDetail} onClickClose={() => setIsShowingDetail(false)}></FullPageProjectDetail>
      <Swipeable
        onSwipedLeft={() => {
          setCurrentIndex(prev => Math.min(prev + 1, PROJECT_DATA.length - 1))
        }}
        onSwipedRight={() => {
          setCurrentIndex(prev => Math.max(prev - 1, 0))
        }}
      >
        <PageContainer style={{ backgroundColor: currentProjectData.backgroundColor }} onS>
          <CurrentProjectContainer>
            {PROJECT_DATA.map((data, index) => {
              const slideState = index - currentIndex
              return <ProjectSlide key={data.name} {...currentProjectData} slideState={slideState} onClick={() => slideClick(slideState)} >
                {data.isomorph}
                <ProjectSlideTitle {...currentProjectData}>{data.name}</ProjectSlideTitle>
              </ProjectSlide>
            })}
          </CurrentProjectContainer>
          <AboutThisProjectButton {...currentProjectData} onClick={
            () => setIsShowingDetail(true)
          }>About this project</AboutThisProjectButton>
        </PageContainer>
      </Swipeable>

    </Layout>
  )
}

export default IndexPage
